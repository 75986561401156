import React from "react";
import Layout from "../layout";
import PageCover from "../components/PageCover";
import PicAndText from "../components/PicAndText";
import { Col, Container, Row } from "react-bootstrap";
import { Add, Category, Euro, LocalShipping } from "@material-ui/icons";
import SEO from "../components/seo";
// import  Coverimage  from '../img/frontimg.jpg';

export default function homepage() {
  return (
    <>
      <SEO title="Etusivu" />
      <Layout>
        <PageCover />
        <Container className="pagecontainer">
          <Col className="text-center mt-5">
            <Row>
              <p style={{ fontSize: "22px" }}>
                Oletko koskaan miettinyt miksi markkinoilla ei ole edullista,
                tehokasta ja helppokäyttöistä myynnin-, tuotannon- ja
                toiminnanohjausjärjestelmää, johon ei tarvitse pitkiä
                koulutuksia tai raskaita käyttöönottoja?
              </p>
            </Row>
            <Col className="nyton text-center mt-5 mb-5">
              <h2>Nyt on!</h2>
            </Col>
            <hr></hr>
          </Col>

          <Row>
            <Col></Col>
            <Col md="5" sm="12">
              <PicAndText
                IconElement={Euro}
                header="Asiakkaat ja myynti"
                text="Asiakasrekisterit, käynnit, tarjoukset ja tilaukset kätevästi sijainnista riippumatta myös mobiililaitteilla. Soveltuu erinomaisen hyvin myös liikkuvalle myyjälle. Laaja raportointi."
              />
            </Col>
            <Col md="5" sm="12">
              <PicAndText
                IconElement={LocalShipping}
                header="Tuotanto ja toimitus"
                text="Tilauksen käsittely tuotantoon ja toimitetuksi. Tuotantoerät mahdollistamaan entistäkin tehokkaamman tuotannon. Varastosaldot päivittyvät prosessin mukana reaaliaikaisesti taustalla."
              />
            </Col>
            <Col></Col>
          </Row>
          <Row>
            <Col></Col>
            <Col md="5" sm="12">
              <PicAndText
                IconElement={Category}
                header="Varasto"
                text="Automaattisesti ajan tasalla oleva varasto ja inventaarioraportti yhdellä napin painalluksella. Varaston ennakointi tilauskannan perusteella."
              />
            </Col>
            <Col md="5" sm="12">
              <PicAndText
                IconElement={Add}
                header="Lisätoiminnot"
                text="Muun muassa tarkka katelaskenta tarjouksittain ja tilauksittain sekä asiakkaittan ja myyjittäin, sisäinen tiedostopankki, laajat raportointimahdollisuudet ja ennen kaikkea helppokäyttöinen ja tehokas nykyaikainen järjestelmä."
              />
            </Col>
            <Col></Col>
          </Row>
        </Container>
      </Layout>
    </>
  );
}
