import React from "react";
import "./pagecover.css";
import MerppiLogo from "./MerppiLogo";
import { Button } from "react-bootstrap";
import { Link as GatsbyLink } from "gatsby";
//import  background from "../img/frontimg2.jpg";
import { useStaticQuery, graphql } from "gatsby";
//import Img from 'gatsby-image'
import BackgroundImage from "gatsby-background-image";
import { getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";

// How to use gatsby-background image with gatsby 3 check:
// https://www.gatsbyjs.com/plugins/gatsby-background-image/#gatsby-3--gatsby-plugin-image
export default function PageCover() {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "frontimg2.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 90)
        }
      }
    }
  `);

  const image = getImage(data.file);
  const bgImage = convertToBgImage(image);

  return (
    <BackgroundImage
      className="pagecoverbg container-fluid"
      backgroundColor={`#ffffff`}
      {...bgImage}
      preserveStackingContext
    >
      <div className="biglogo img-fluid">
        <MerppiLogo />
        <div className="row justify-content-center mt-5">
          <h4>Myynnin-, tuotannon- ja toiminnanohjausjärjestelmä</h4>
        </div>
        <Button
          as={GatsbyLink}
          to="/contact"
          variant="warning"
          size="lg"
          className="contactbtn"
        >
          OTA YHTEYTTÄ!
        </Button>
      </div>
    </BackgroundImage>
  );
}
