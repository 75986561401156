import React from "react";
import { Container, Button } from "react-bootstrap";
import "./picandtext.css";
import { makeStyles } from "@material-ui/styles";
//import { Link } from 'react-router-dom';
import { Link } from "gatsby"

const PicAndText = ({ IconElement, header, text, URL }) => {

  const useStyles = makeStyles({
  iconStyle: {
    width: "50px",
    height: "50px",
    color: "white",
  },
});

const styles = useStyles();
  // const IconComponent = React.Component(IconElement);
  return (
    <Container className="picandtext">
      <Button disabled className="ball mt-4">
        <div>
          <IconElement className={styles.iconStyle}></IconElement>
        </div>
      </Button>
      <h2>{header}</h2>
      <p className="paragraph">{text}</p>
    </Container>
  );
};

export default PicAndText;
